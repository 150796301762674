import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { backendApi } from '../../../../../common/reducers/backend-api/backend-api';

interface AssociateSubscriptionActionProps {
    userId: string;
    open: boolean;
    onClose: () => void;
    onSuccess: (message: string) => void;
    onError: (message: string) => void;
}

export function AssociateSubscriptionAction({ userId, open, onClose, onSuccess, onError }: AssociateSubscriptionActionProps) {
    const [customerId, setCustomerId] = useState('');
    const [associateSubscription] = backendApi.endpoints.associateSubscription.useMutation();

    const handleSubmit = async () => {
        try {
            await associateSubscription({ customerId }).unwrap();
            onSuccess('Subscription associated successfully');
            onClose();
        } catch (error: any) {
            onError(error.message || 'Failed to associate subscription');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Associate Subscription</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Chargebee Customer ID"
                    fullWidth
                    value={customerId}
                    onChange={(e) => setCustomerId(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" disabled={!customerId}>
                    Associate
                </Button>
            </DialogActions>
        </Dialog>
    );
} 