import { apiCall } from './api-call-helper';


export async function deleteUserMessageAndCreateLog(messageId: string, waMessageKey: string, waMessageKeyPart2: string = ''): Promise<{
  isLogCreated: boolean,
  isMessageDeleted: boolean,
  isRecurringScheduled: boolean,
}> {
  const data = {
    messageId,
    waMessageKey,
    waMessageKeyPart2,
  };

  return apiCall<any>('post', '/actions?cmd=delete-user-message-and-create-log', data);
}

export async function hasSendAttemptBeenMadeToMessage(messageId: string): Promise<any> {
  const data = {
    messageId,
  };

  return apiCall<any>('post', '/actions?cmd=has-send-attempt-been-made-to-message', data);
}

export async function getNextCampaignMessage(campaignId: string): Promise<any> {
  const data = {
    campaignId,
  };

  return apiCall<any>('post', '/actions?cmd=next-campaign-message', data);
}

export async function updateUserMessageAck(messageId: string, ack: number): Promise<any> {
  const data = {
    messageId,
    ack,
  };

  return apiCall<any>('post', '/actions?cmd=update-user-message-ack', data);
}

export async function getSettingsRequest(): Promise<any> {

  return apiCall<any>('post', '/actions?cmd=get-settings');
}
