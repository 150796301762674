import Button from '@mui/material/Button';
import { cancelSubscriptionThunk, openCheckoutPageThunk } from '@common-reducers/PaymentFormThunks';
import { useAppDispatch } from '@common-reducers/hooks/store.hook';
import { useConfirm } from 'material-ui-confirm';
import React from 'react';

interface ChargebeeButtonProps {
    label: string;
    plan: string;
    seats: number;
    duration: string;
    disabled: boolean;
    onClick?: () => void;
}

const ChargebeeButton: React.FC<ChargebeeButtonProps> = ({
    label,
    plan,
    duration,
    disabled,
    seats,
}) => {
    const dispatch = useAppDispatch();
    const confirm = useConfirm();
    const planId = `${plan}-USD-${duration}`;
    

    const startCheckout = async () => {
        if (plan.toLowerCase() === 'free') {
            try {
                await confirm({
                    title: 'Cancel Subscription',
                    description: 'Are you sure you want to cancel your subscription? This action cannot be undone.',
                    confirmationText: 'YES, CANCEL SUBSCRIPTION',
                    cancellationText: 'NO, KEEP SUBSCRIPTION',
                    cancellationButtonProps: { 
                        variant: 'contained', 
                        color: 'primary'
                    },
                    confirmationButtonProps: { 
                        variant: 'outlined', 
                        color: 'error'
                    }
                });
                console.log('Cancel active subscription');
                dispatch(cancelSubscriptionThunk());
            } catch {
                // User canceled the confirmation
            }
        } else {
            dispatch(openCheckoutPageThunk({
                item_price_id: planId,
                quantity: seats
            }));
        }
    };

    return (
        <Button
            onClick={startCheckout}
            variant="contained"
            color="primary"
            disabled={disabled}
        >
            {label}
        </Button>
    );
};

export default ChargebeeButton;