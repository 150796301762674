import BTButton from "@common-components/bt-button/bt-button";
import { Stack } from "@mui/system";
import { useRouter } from "@common-reducers/hooks/use-router";
import { useAppDispatch, useAppSelector } from "@common-reducers/hooks/store.hook";
import Lottie from 'lottie-react';
import { useEffect } from "react";

import CheckLottie from '@common-assets/lottie/check-animation.json';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { closePaymentDialogThunk } from "@common-reducers/PaymentFormThunks";

import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import { AnalyticsService } from "@common-services/analytics-service";
import { namespace } from "@common-config/constants";
import { useTranslation } from "react-i18next";
import { syncUserData } from "@common-reducers/backend-api/backend-api";
export interface ThankYouViewProps { }

export interface ThankYouViewState {
  loading: boolean;
  message?: string;
}

export const ThankYouView = () => {
  const { t } = useTranslation(namespace);
  const analyticsService: AnalyticsService = useAnalyticsService();
  const paymentSuccessType = useAppSelector((state) => state.PaymentFormReducer.paymentSuccessType);

  const dispatch = useAppDispatch()
  const router = useRouter();

  useEffect(() => {
    document.title = t('thankYouView.documentTitle');;
  }, [t]);

  useEffect(() => {
    analyticsService.event("thank_you_page", {
      category: "upgrade",
      action: "thank you page",
    });
    dispatch(syncUserData.initiate({})).unwrap()
  }, []);

  return (
    <Stack style={{ padding: "100px" }} alignItems="center" justifyContent="center">
      <img style={{ width: "200px" }} alt="" src="/img/logo.png" />
      <Lottie autoPlay={true} loop={true} animationData={CheckLottie} style={{ width: '200px', height: '200px' }} />
      <h2 style={{ margin: "10px" }}>
        {t('thankYouView.successfully')} {paymentSuccessType === 'upgrade' ? t('thankYouView.updated') : t('thankYouView.updated')}{" "}{t('thankYouView.yourAccount')}
      </h2>
      <div>
        {t('thankYouView.confirmationMessage')}
      </div>
      {/* <img src="/img/mastertime.jpg" alt="illustration" /> */}

      {/* <Stack spacing={1} sx={{ padding: '5% 10%', background: 'var(--default-background)', zIndex: '5', borderRadius: '0 0 12px 12px' }}>
        <h3 style={{ fontSize: '18px', fontWeight: 700, color: 'var(--secondary-text-color)', margin: '15px 0', borderRadius: '0 0 12px 12px' }}>{paymentParams?.plan.planName}</h3>
        <Stack sx={{ padding: '0 15px' }} spacing={2}>

          <Box sx={{ height: 120 }}>
            {cartInfo && (!calculateCartIsLoading) && paymentParams && <>
              <Stack direction='row' justifyContent='space-between'>
                <span className={classes.sumDetails}>Price</span>
                <span>{currencySymbol}{paymentParams.plan?.basePrice}</span>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <span className={classes.label}>{describeDurationPlanDiscount}</span>
                <span>{`-${currencySymbol}${Math.round(Math.round(paymentParams?.plan?.annualDiscount * paymentParams?.plan?.basePrice))}`}</span>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <span className={classes.label}>{describeSeatPlanDiscount}</span>
                <span>{paymentParams?.plan?.seatDiscount && `-${currencySymbol}${Math.round(paymentParams?.plan?.seatDiscount * paymentParams?.plan?.basePrice)}`}</span>
              </Stack>
              {appliedCouponCode && cartCouponsTotal && <Stack direction='row' justifyContent='space-between'>
                <span className={classes.label}>{describeCouponDiscount}</span>
                <span className={classes.sumDetails}>-{currencySymbol}{cartCouponsTotal}</span>
              </Stack>}
              {cartInfo && (
                <Stack direction='row' justifyContent='space-between'>
                  <span className={classes.label}><b>Total</b></span>
                  <span className={`${classes.sumDetails}`}>{currencySymbol}{cartTotal}</span>
                </Stack>
              )}
            </>}
          </Box>

        </Stack>

      </Stack> */}

      <div style={{
        padding: "30px",
      }}>
        <BTButton
          data-testid='successful-upgrade-get-started-button'
          onClick={async () => {
            await dispatch(closePaymentDialogThunk()).unwrap();
            router.navigate({
              pathname: '/tasks'
            });
          }}
          endIcon={<ArrowForwardIcon />}>{t('thankYouView.getStartedButton')}</BTButton>
      </div>

    </Stack>

  );
}

export default ThankYouView;
