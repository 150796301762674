//import { googleLogout } from '@react-oauth/google';
import {
  backendApi
} from "@common-reducers/backend-api/backend-api";
import { AnalyticsService } from "@common-services/analytics-service";
import { UserInterface } from "@common/models/user";
import { BLUETICKS_TOKEN_KEY } from "@common/reusable/common";
import { MessageServiceActions } from "@common/services/messageServiceActions";
import {
  PayloadAction,
  createSlice,
  isFulfilled,
  isRejected
} from "@reduxjs/toolkit";
import { initAuth } from "./AuthThunks";

const analyticsService = new AnalyticsService();

export interface SignupFormState {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  acceptedTerms?: boolean;
}

// Define a type for the slice state
export interface UserState {
  authenticating: boolean;
  accessToken?: string | null;
  userId?: string;
  authError?: string;
  socketId?: string;
  socketModalOpen: boolean;
  hideSocketModal: boolean;
  signup: SignupFormState;
  direction: string;
  phone?: string;
}

// Define the initial state using that type
const initialState: UserState = {
  authenticating: true,
  signup: { email: "", password: "", firstName: "", lastName: "" },
  socketModalOpen: false,
  hideSocketModal: false,
  accessToken:
    typeof window !== "undefined"
      ? window.localStorage.getItem(BLUETICKS_TOKEN_KEY)
      : undefined,
  direction: "ltr",
};


export const userSlice = createSlice({
  name: "user",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCredentials: (
      state,
      action: PayloadAction<{ user?: UserInterface; accessToken?: string }>
    ) => {
      state.userId = action.payload.user._id;
      state.accessToken = action.payload.accessToken;
    },
    logout: (state) => {
      delete state.userId;
      delete state.accessToken;
      // clear analytics user identity
      analyticsService.clearUserIdentity();
    },
    clearAuthError: (state) => {
      delete state.authError;
    },
    setSocketId: (state, action: PayloadAction<string | undefined>) => {
      state.socketId = action.payload;
    },
    setAuthenticating: (state, action: PayloadAction<boolean>) => {
      state.authenticating = action.payload;
    },
    setSignupState: (state, action: PayloadAction<SignupFormState>) => {
      state.signup = action.payload;
    },
    setHideSocketModal: (state, action: PayloadAction<boolean>) => {
      state.hideSocketModal = action.payload;
    },
    setUserDirection(state, { payload }: PayloadAction<string>) {
      state.direction = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isFulfilled(initAuth), (state) => {
      state.authenticating = false;
    });
    builder.addMatcher(isRejected(initAuth), (state) => {
      state.authenticating = false;
    });
    builder.addMatcher(
      backendApi.endpoints.authenticate.matchPending,
      (state, payload) => {
        console.log(`Starting authentication`);
        if (!state.userId) {
          state.authenticating = true;
        }
        delete state.authError;
      }
    );
    builder.addMatcher(
      backendApi.endpoints.register.matchPending,
      (state, payload) => {
        state.authenticating = true;
        delete state.authError;
      }
    );
    builder.addMatcher(
      backendApi.endpoints.authenticate.matchRejected,
      (state, payload) => {
        console.log(`Authentication error!`);
        console.log(payload);
        state.authenticating = false;

        if (payload.meta.arg.originalArgs.strategy === "local") {
          const message = (payload?.payload?.data as any)?.message;
          if (message === "Invalid login") {
            state.authError = `Invalid password. Please retry or reset by "Forgot your password?"`;
          } else {
            state.authError = message || "An error occurred";
          }

          analyticsService.event("login_failed", {
            category: "access",
            action: "login-failed",
          });
        }
      }
    );
    builder.addMatcher(
      backendApi.endpoints.register.matchRejected,
      (state, payload) => {
        state.authenticating = false;

        const payloadErrorData: any = payload?.payload?.data;

        switch (payloadErrorData?.name) {
          case "Conflict":
            state.authError = "User already exists";
            break;
          default:
            state.authError = payloadErrorData?.message || "An error occurred";
            break;
        }

        analyticsService.event("login_failed", {
          category: "access",
          action: "signup-failed",
        });
      }
    );
    builder.addMatcher(
      backendApi.endpoints.authenticate.matchFulfilled,
      (state, payload) => {
        console.log(`Authentication success!`);

        state.userId = payload.payload.user._id;
        state.accessToken = payload.payload.accessToken;

        analyticsService.identify(payload.payload.user);

        if (typeof window !== "undefined") {
          window.localStorage.setItem(BLUETICKS_TOKEN_KEY, state.accessToken);
        }
        MessageServiceActions.postMessageToBackgroundWithResponse<string>("set-token", {});
        MessageServiceActions.postMessageWithResponse("accessToken", { 
          accessToken: state.accessToken
        });
    

        if (payload.meta.arg.originalArgs.strategy === "local") {
          analyticsService.event("login_success", {
            category: "access",
            action: "login-success",
          });
        }
      }
    );
    builder.addMatcher(
      backendApi.endpoints.register.matchFulfilled,
      (state, payload) => {
        state.authenticating = false;

        analyticsService.event("login_success", {
          category: "access",
          action: "signup-success",
        });
      }
    );
  },
});

export const {
  setCredentials,
  clearAuthError,
  logout,
  setSocketId,
  setAuthenticating,
  setSignupState,
  setHideSocketModal,
  setUserDirection,
} = userSlice.actions;

export default userSlice.reducer;
