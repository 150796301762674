import { ReactComponent as AttachFileSVG } from "@common-assets/attachFileSVG.svg"
import BTTextField from '@common-components/atoms/text-field/bt-text-field'
import AttachmentPreview from '@common-components/attachment-preview/attachment-preview'
import RowStack from '@common-components/common/row-stack/RowStack'
import WSButton from '@common-components/ws-button/ws-button'
import { Template } from '@common-models/template'
import { setCurrentTemplate } from '@common-reducers/CampaignReducer'
import { setMentions } from "@common-reducers/WhatsAppReducer"
import { useGetLinkPreviewMutation, useGetSignedUploadUrlMutation } from '@common-reducers/backend-api/backend-api'
import { useAppDispatch, useAppSelector, useDebounce } from '@common-reducers/hooks/store.hook'
import { blobMap } from '@common-services/blob-service'
import { getBase64ImageFromUrl, getUrls, safeCreateObjectURL, uploadFilePut } from '@common-services/utils'
import { orangeStyle, textFieldSX } from '@common/reusable/commonStyles'
import { theme } from '@common/theme'
import BulkButton from '@extension/context/content/components/bt-bulk-dashboard/bulk-button'
import { AddOutlined, CloseOutlined, DeleteOutline, Download, FormatBold, FormatItalic, SaveAsOutlined, StrikethroughS } from '@mui/icons-material'
import { Button, CircularProgress, IconButton, Menu, Modal, Snackbar, Stack, Switch, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { IoMdCloseCircle } from 'react-icons/io'
import { MdInsertEmoticon } from 'react-icons/md'
import Resizer from 'react-image-file-resizer'
import CreatePoll from "./create-poll"
import MessageEditorTemplateDropdown from './message-editor-template-dropdown'
import ReplyToMessageChip from "./reply-to-message-chip"
import TemplateHeader from './template-header'
import TemplatePlaceholderBar from './template-placeholder-bar'

export type Poll = {
  question?: string;
  options?: { id: number; text: string; order: number }[];
  allowMultiple?: boolean;
}

const initialPoll = {
  question: '',
  options: [
    { id: Math.floor(Math.random() * 1000000000000000000000000), text: '', order: 0 },
    { id: Math.floor(Math.random() * 1000000000000000000000000), text: '', order: 1 },
  ],
  allowMultiple: false,
}

type Props = {
  showTemplateButtons: boolean;
  value?: Template;
  onSaveNew?: (newTemplate: any) => Promise<void>;
  isPreview?: boolean;
  isDisplayRemoveAttachmentButttom?: boolean;
  onChange: (value) => void;
  editorContext: 'scheduleMessage' | 'scheduleCampaign';
}

export default function MessageEditor({
  showTemplateButtons,
  value,
  onSaveNew,
  isPreview = false,
  isDisplayRemoveAttachmentButttom = true,
  editorContext,
  onChange,
}: Props) {
  const dispatch = useAppDispatch()
  const activeBulkView = useAppSelector((state) => state.CampaignReducer.activeBulkView);
  const currentChat = useAppSelector((state) => state.WhatsAppReducer.currentChat);
  const pollFromWAModal = useAppSelector(state => state.AddScheduledMessageButtonReducer.pollFromWAModal)
  const activeTab = useAppSelector((state) => state.CampaignReducer.activeTab);


  const [getLinkPreview] = useGetLinkPreviewMutation();
  const [getSignedUploadUrl] = useGetSignedUploadUrlMutation();
  const templateMessageArea = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
  const emojiButtonRef = useRef();

  const [localPollState, setLocalPollState] = useState<Partial<Poll>>(value._id ? value.poll : initialPoll)
  const [editorType, setEditorType] = useState<'message' | 'poll'>(value?.isPoll ? 'poll' : 'message');
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const [isEmojiPicker, seIsEmojiPicker] = useState<boolean>(false);
  const [linkPreviewLoading, setLinkPreviewLoading] = useState(false);
  const [name, setName] = useState('');
  const [open, setOpen] = React.useState(false);
  const [templateLocalState, setTemplateLocalState] = useState<Partial<Template>>(value)
  const [isEditMode, setEditMode] = useState<boolean>(activeBulkView === "campaignNewTemplateViewFromManagement"
    || activeBulkView === "campaignNewTemplateViewFromNewCampaignView"
    || activeBulkView === "campaignNewTemplateViewFromWhatsappView");
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>()
  const [isMessageEmpty, setIsMessageEmpty] = useState<boolean>(templateLocalState?.message === undefined || templateLocalState?.message === null || templateLocalState?.message === '');
  const [selectionRange, setSelectionRange] = useState({ start: 0, end: 0 });


  const isAttachmentSound = templateLocalState?.asset?.type?.includes('audio')
  const debounceMessageObject = templateLocalState
  const debouncedInternalMessageTemplateValue = useDebounce(debounceMessageObject, 500);
  const debouncedInternalPollValue = useDebounce(localPollState, 500);
  const linkPreviewImage = `data:image/jpeg;base64,${templateLocalState?.linkPreview?.thumbnail}`;
  let [messageBGColor, linkPreviewBGColor, toggleTrack, toggleSwitch, linkPreviewInputBorder] = useMemo(() => {
    let _messageBGColor
    let _linkPreviewBGColor
    let toggleTrack
    let toggleSwitch
    let linkPreviewInputBorder
    if (editorContext === 'scheduleMessage') {
      _messageBGColor = 'var(--schedule-message-editor-input-bg) !important'
      _linkPreviewBGColor = 'var(--schedule-message-editor-link-preview-bg) !important'
      toggleTrack = 'var(--schedule-message-toggle-track) !important'
      toggleSwitch = 'var(--schedule-message-toggle-switch) !important'
      linkPreviewInputBorder = 'var(--schedule-message-link-preview-input-border) !important'
    }
    if (editorContext === 'scheduleCampaign') {
      _messageBGColor = 'var(--campaign-message-editor-input-bg) !important'
      _linkPreviewBGColor = 'var(--campaign-message-editor-link-preview-bg) !important'
      toggleTrack = 'var(--campaign-message-toggle-track) !important'
      toggleSwitch = 'var(--campaign-message-toggle-switch) !important'
      linkPreviewInputBorder = 'var(--campaign-message-link-preview-input-border) !important'
    }

    return [_messageBGColor, _linkPreviewBGColor, toggleTrack, toggleSwitch, linkPreviewInputBorder]
  }, [editorContext])


  const handleMessageChange = (message: string) => {
    if ((isMessageEmpty && message !== '') || (!isMessageEmpty && message === '')) {
      setIsMessageEmpty(message === '')
    }
    setTemplateLocalState({
      ...templateLocalState,
      message
    })
  };

  const onEmojiClick = (emoji: EmojiClickData, event: MouseEvent) => {
    const cursorPosition = selectionRange?.end;
    const message = templateLocalState.message || '';
    let updatedMessage;

    if (cursorPosition !== undefined) {
      updatedMessage = [
        message.slice(0, cursorPosition),
        emoji.emoji,
        message.slice(cursorPosition)
      ].join('');
    } else {
      updatedMessage = message + emoji.emoji;
    }

    setTemplateLocalState({
      ...templateLocalState,
      message: updatedMessage
    });
  };


  const resizeFile = useCallback(async (file: File | Blob): Promise<File> => {
    let retVal;
    try {
      const resizedImage = await new Promise<string>((resolve, reject) => {
        Resizer.imageFileResizer(file, 200, 200, 'JPEG', 100, 0, (image: string) => resolve(image), 'file');
      })
      retVal = resizedImage;
    } catch (e) {
      console.warn('Unable to resize image')
    }
    return retVal;
  }, [])

  const [previewUrl] = useMemo(() => getUrls(templateLocalState?.message ?? ''), [templateLocalState?.message]);

  const updateLinkPreview = useCallback(async () => {
    setLinkPreviewLoading(true);
    const link = previewUrl;
    console.log(`updateLinkPreview: ${link}`)
    if (link) {
      const linkPreviewResponse = await getLinkPreview({ link })
      console.log("[message-editor.tsx] linkPreviewResponse:");
      console.log(linkPreviewResponse);
      if ("data" in linkPreviewResponse) {
        let thumbnail;
        if (linkPreviewResponse.data.thumbnailUrl) {
          thumbnail = await getBase64ImageFromUrl(linkPreviewResponse.data.thumbnailUrl);
        }

        // setInternalValue({
        //   ...value,
        setTemplateLocalState({
          ...templateLocalState,
          sendLinkPreview: true,
          linkPreview: {
            ...linkPreviewResponse.data,
            thumbnail
          }
        })
      }
    }
    setLinkPreviewLoading(false);
  }, [getLinkPreview, previewUrl, templateLocalState])

  const handleSelectedFiles = useCallback(async (acceptedFiles: File[]) => {
    removeAttachment();
    setLoadingAttachment(true);
    const [attachment] = acceptedFiles;

    // Handle no attachment
    if (!attachment) {
      setLoadingAttachment(false);
      return;
    }

    const uploadUrlResponse: { url: string; publicUrl: string } = await getSignedUploadUrl(attachment?.type).unwrap();

    try {
      await uploadFilePut(attachment, uploadUrlResponse.url);

      let updatedMessage;
      if (value.sendLinkPreview) {

        const thumbnailFile: File = await resizeFile(attachment);
        const uploadThumbnailUrlResponse: { url: string; publicUrl: string } =
          await getSignedUploadUrl(thumbnailFile?.type).unwrap();
        await uploadFilePut(thumbnailFile, uploadThumbnailUrlResponse.url);

        const thumbnail = await getBase64ImageFromUrl(uploadThumbnailUrlResponse.publicUrl);
        const newTitle = value?.linkPreview?.title ?? attachment.name;

        updatedMessage = {
          ...templateLocalState,
          linkPreview: {
            ...templateLocalState?.linkPreview,
            images: [uploadUrlResponse.publicUrl],
            title: newTitle,
            thumbnail,
            thumbnailUrl: uploadThumbnailUrlResponse.publicUrl
          }
        }
      } else {
        updatedMessage = {
          ...templateLocalState,
          asset: {
            url: uploadUrlResponse.publicUrl,
            name: attachment.name,
            type: attachment.type,
          }
        };
      }
      setTemplateLocalState(updatedMessage)
    } catch (e) {
      console.error(e);
    }

    setLoadingAttachment(false);
  }, [getSignedUploadUrl, templateLocalState])

  const onDrop = useCallback(acceptedFiles => { handleSelectedFiles(acceptedFiles) }, [handleSelectedFiles])

  const { getRootProps, getInputProps, inputRef, isDragActive } = useDropzone({ onDrop, noClick: true })
  const rootProps = getRootProps();
  const inputProps = getInputProps();

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    handleSelectedFiles(Array.from(e.target.files))
  };

  const removeAttachment = () => {
    setTemplateLocalState({
      ...templateLocalState,
      asset: undefined,
      imageId: undefined,
      linkPreview: {
        ...templateLocalState?.linkPreview,
        thumbnail: undefined
      }
    })
  };

  //const imageBlob = templateLocalState?.sendLinkPreview ? undefined : blobMap?.get(templateLocalState?.imageId);

  let attachment
  if (templateLocalState?.sendLinkPreview) {
    if (templateLocalState?.linkPreview?.thumbnail) {
      attachment = {
        name: '',
        type: 'image/jpeg',
        url: linkPreviewImage
      };
    }
  } else {
    attachment = templateLocalState?.asset;
  }


  async function downloadAttachment(attachmentUrl: string, attachmentName: string) {
    try {
      const response = await fetch(attachmentUrl);
      const blob = await response.blob();
      const downloadUrl = safeCreateObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute('download', attachmentName || 'download');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download failed', error);
    }
  }


  const attachmentBox = () => <Stack spacing={2}>
    {(attachment) && !(templateLocalState?.sendLinkPreview ?? false) && (
      <div
        style={{
          position: "relative",
        }}
      >

        <Stack alignItems="center" justifyContent="center">
          <RowStack sx={{ mb: 2 }}>
            {isDisplayRemoveAttachmentButttom && <Button
              variant="outlined"
              startIcon={<DeleteOutline />}
              onClick={removeAttachment}
              style={{
                ...orangeStyle,
                marginRight: '10px',
              }}
            >
              REMOVE ATTACHMENT
            </Button>}
            <IconButton onClick={() => downloadAttachment(attachment?.url, attachment?.name)}>
              <Download />
            </IconButton>
          </RowStack>

          <AttachmentPreview
            isFromCampaigns={true}
            attachmentName={attachment?.name}
            attachmentType={attachment?.type}
            attachmentUrl={attachment?.url}
          />
        </Stack>
      </div>
    )}
  </Stack >

  const linkPreview = () => {
    return (
      <>
        <Stack direction="row" sx={{ position: 'relative' }} spacing={2}>
          <Stack direction="row" alignItems="center">
            <Switch checked={templateLocalState?.sendLinkPreview ?? false} inputProps={{ 'aria-label': 'Link Preview' }} onChange={(event) => {
              setTemplateLocalState({
                ...templateLocalState,
                sendLinkPreview: event.target.checked,
              })
            }} sx={{
              '& .MuiSwitch-switchBase': {
                color: (templateLocalState?.sendLinkPreview ?? false) ? toggleSwitch : '#9b9d9e !important',
              },
              '& .MuiSwitch-track': {
                backgroundColor: (templateLocalState?.sendLinkPreview ?? false) ? toggleTrack : '#a8a8a8 !important',
              },
            }} />
            <Typography variant='body1' >Link Preview</Typography>
          </Stack>
          {previewUrl &&
            <Box sx={{ position: 'relative' }}>
              <BulkButton
                onClick={() => updateLinkPreview()}
                buttonString='Update Preview'
                Icon={AddOutlined}
              />
              {
                linkPreviewLoading &&
                <Stack alignItems="center" justifyContent="" sx={{
                  height: '100%',
                  width: '100%',
                  pointerEvents: 'none'
                }}><CircularProgress size={20} sx={{
                  display: 'block',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  margin: 'auto',
                  position: 'absolute',
                }} /></Stack>}
            </Box>}
        </Stack>

        {(templateLocalState?.sendLinkPreview ?? false) &&
          <Stack direction='column'>
            <Box sx={{ marginBottom: '13px', marginLeft: '20px', }}><TextField sx={{
              width: '90%',
              p: '3px 16px 3px 24px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${linkPreviewInputBorder}`,
                height: '42px',
                borderRadius: '21px',
              },
              '& .MuiInputBase-input': {
                color: 'var(--primary)',
                padding: '7px 1px 1px 27px',
              },
            }}
              onChange={(event) => {
                setTemplateLocalState({
                  ...templateLocalState,
                  linkPreview: {
                    ...templateLocalState?.linkPreview,
                    title: event.target.value
                  }
                })
              }}
              value={templateLocalState?.linkPreview?.title ?? ''}
              placeholder='Title'>

            </TextField></Box>
            <Box sx={{ marginBottom: '13px', marginLeft: '20px', }}><TextField sx={{
              width: '90%',
              p: '3px 16px 3px 24px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${linkPreviewInputBorder}`,
                height: '42px',
                borderRadius: '21px',
              },
              '& .MuiInputBase-input': {
                color: 'var(--primary)',
                padding: '7px 1px 1px 27px',
              },
            }}
              onChange={(event) => {
                setTemplateLocalState({
                  ...templateLocalState,
                  linkPreview: {
                    ...templateLocalState?.linkPreview,
                    description: event.target.value
                  }
                })
              }}
              value={templateLocalState?.linkPreview?.description ?? ''}
              placeholder='Description'>

            </TextField></Box>
            <Box sx={{ marginBottom: '13px', marginLeft: '20px', }}><TextField sx={{
              width: '90%',
              p: '3px 16px 3px 24px',
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${linkPreviewInputBorder}`,
                height: '42px',
                borderRadius: '21px',
              },
              '& .MuiInputBase-input': {
                color: 'var(--primary)',
                padding: '7px 1px 1px 27px',
              },
            }}
              onChange={(event) => {
                setTemplateLocalState({
                  ...templateLocalState,
                  linkPreview: {
                    ...templateLocalState?.linkPreview,
                    url: event.target.value
                  }
                })
              }}
              value={templateLocalState?.linkPreview?.url ?? ''}
              placeholder='Link Preview URL'>
            </TextField></Box>
          </Stack>
        }
      </>
    )
  }

  const linkPreviewBox = () => {
    return (
      <Stack direction='column' sx={{
        width: "100%",
        backgroundColor: messageBGColor,
        borderRadius: '8px',
        p: '4px 8px 4px 8x',
      }}>
        <Stack direction='row' sx={{
          backgroundColor: linkPreviewBGColor,
          borderRadius: '8px',
          m: 2
        }}>
          {(attachment) && (
            <div
              style={{
                position: "relative",
                padding: theme.spacing(2),
                height: '74px',
                width: '74px',
              }}
            >

              <Stack sx={{
                width: '100%', height: '74px',
              }} alignItems="center" justifyContent="center">
                <AttachmentPreview
                  isFromCampaigns={true}
                  attachmentName={attachment?.name}
                  attachmentType={attachment?.type}
                  attachmentUrl={attachment?.url}
                />
              </Stack>
            </div>
          )}
          <Stack direction='column' sx={{
            height: '74px',
            width: '100%',
            p: 2,
          }}>
            <input style={{
              fontWeight: 800,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'var(--primary)',
              fontSize: '18px',
              backgroundColor: 'transparent',
              width: '100%',
              border: '0px'
            }}
              placeholder='Title'
              disabled={isPreview}
              onChange={(event) => {
                setTemplateLocalState({
                  ...templateLocalState,
                  linkPreview: {
                    ...templateLocalState?.linkPreview,
                    title: event.target.value
                  }
                })
              }}
              value={templateLocalState?.linkPreview?.title ?? ''}
            />
            <input style={{
              fontWeight: 400,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '15px',
              backgroundColor: 'transparent',
              color: 'var(--primary)',
              width: '100%',
              border: '0px',
              height: '1005',
            }}
              placeholder='Description'
              disabled={isPreview}
              onChange={(event) => {
                setTemplateLocalState({
                  ...templateLocalState,
                  linkPreview: {
                    ...templateLocalState?.linkPreview,
                    description: event.target.value
                  }
                })
              }}
              value={templateLocalState?.linkPreview?.description ?? ''}
            />
            <input onChange={(event) => {
              setTemplateLocalState({
                ...templateLocalState,
                linkPreview: {
                  ...templateLocalState?.linkPreview,
                  url: event.target.value
                }
              })
            }}
              placeholder='Link preview URL'
              value={templateLocalState?.linkPreview?.url ?? ''}
              disabled={isPreview}
              style={{
                fontWeight: 200,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '10px',
                color: 'var(--primary)',
                backgroundColor: 'transparent',
                width: '100%',
                border: '0px',
                justifySelf: 'flex-end'
              }}
            />
          </Stack>
        </Stack >
      </Stack >
    )
  }


  const formatBySymbol = (symbol: '*' | '~' | '_') => {
    const { start, end } = selectionRange;
    if (start !== end) {
      const selectedText = templateLocalState?.message.slice(start, end);
      const newText = `${templateLocalState?.message.slice(0, start)}${symbol}${selectedText}${symbol}${templateLocalState?.message.slice(end)}`;
      setTemplateLocalState({
        ...templateLocalState,
        message: newText
      })
    }
  };

  const fontFormatButtons = () => {
    return (
      <Stack direction="row" sx={{ justifyContent: 'flex-start', alignItems: 'center', }}>
        <IconButton
          sx={{ color: 'var(--primary)', }}
          onClick={() => formatBySymbol('*')}
        >
          <FormatBold />
        </IconButton>
        <IconButton
          sx={{ color: 'var(--primary)', }}
          onClick={() => formatBySymbol('_')}
        >
          <FormatItalic />
        </IconButton>
        <IconButton
          sx={{ color: 'var(--primary)', }}
          onClick={() => formatBySymbol('~')}
        >
          <StrikethroughS />
        </IconButton>
        <IconButton
          sx={{ color: 'var(--primary)', }}
          ref={emojiButtonRef}
          onClick={() => seIsEmojiPicker(!isEmojiPicker)}
          focusRipple={true}
        >
          {isEmojiPicker ? <IoMdCloseCircle /> : <MdInsertEmoticon />}
        </IconButton>
      </Stack>
    )
  }


  const dropFilesOverlay = () => <Stack direction="row" alignItems="center" justifyContent="center" sx={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: theme.spacing(2),
    boxSizing: 'border-box'
  }}>
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{
      borderColor: 'rgba(0,0,0,0.5)',
      borderStyle: "dashed",
      borderWidth: 6,
      width: '100%',
      height: '100%'
    }}>
      <Typography variant="h2">Drop Files Here</Typography>
    </Stack>
  </Stack>;



  const handleSelect = (event) => {
    const inputElement = event.target;
    const selectionStart = inputElement.selectionStart;
    const selectionEnd = inputElement.selectionEnd;

    const displayedString = event.target.value;
    const realString = templateLocalState.message;

    // Array to store the mentions' data
    const mentions = [];
    let displayedIndex = 0;
    let realIndex = 0;

    // Traverse the realString to find mentions and their positions
    while (displayedIndex < displayedString.length && realIndex < realString.length) {
      if (realString[realIndex] === '@' && realString[realIndex + 1] === '[') {
        // Find the end of the 'display' part and 'id' part
        const displayStartIndex = realIndex + 2; // after '@['
        const displayEndIndex = realString.indexOf(']', displayStartIndex);
        const idEndIndex = realString.indexOf(')', displayEndIndex);

        if (displayEndIndex !== -1 && idEndIndex !== -1) {
          const displayedMention = realString.slice(displayStartIndex, displayEndIndex);
          const realMention = realString.slice(realIndex, idEndIndex + 1);

          mentions.push({
            startIndex: displayedIndex,
            displayedLength: displayedMention.length,
            realLength: realMention.length,
          });

          // Move the indices forward
          displayedIndex += displayedMention.length;
          realIndex = idEndIndex + 1;
        }
      } else {
        displayedIndex++;
        realIndex++;
      }
    }

    // Adjust the selectionStart and selectionEnd based on mentions
    let adjustedStart = selectionStart;
    let adjustedEnd = selectionEnd;

    for (const mention of mentions) {
      if (selectionStart > mention.startIndex) {
        adjustedStart += mention.realLength - mention.displayedLength;
      }
      if (selectionEnd > mention.startIndex) {
        adjustedEnd += mention.realLength - mention.displayedLength;
      }
    }

    // Now set the adjusted range
    setSelectionRange({ start: adjustedStart, end: adjustedEnd });
  };

  const textareaRef = useRef<HTMLInputElement>(null);

  const messageBox = () => {
    return (
      <>
        {
          templateLocalState?.sendLinkPreview === true &&
          linkPreviewBox()
        }
        <Stack direction='column' sx={{
          backgroundColor: messageBGColor,
          borderRadius: '8px',
          border: '0px',
        }}>
          <BTTextField
            ref={textareaRef}
            placeholder='Type a message'
            fullWidth={true}
            multiline
            rows={4}
            inputProps={{
              dir: "auto",
            }}
            variant="filled"
            disabled={isPreview}
            onBlur={() => onChange(templateLocalState)}
            value={templateLocalState?.message}
            onChange={(event) => handleMessageChange(event.target.value)}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === '?') {
                event.preventDefault()
                event.stopPropagation()
                handleMessageChange((event.target as HTMLInputElement).value + '?')
              }
            }}
            sx={{
              ...textFieldSX, color: 'rgba(0, 50, 15, 0.65)',
              "& .MuiInputBase-root": {
                backgroundColor: 'transparent!important',
              },
            }}
            onSelect={handleSelect}
          />
        </Stack>
      </>
    )
  }


  const handleOpen = (event) => {
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleSave = async () => {

    const template = { ...templateLocalState };
    delete template._id


    onSaveNew({
      ...template,
      name,
    });
    handleClose();
  };


  const saveNewTemplateButton = () => {
    return (
      <>
        {!isMessageEmpty &&
          <>
            {templateLocalState?._id &&
              <Button onClick={handleOpen} style={{
                textTransform: 'none',
                height: '20px',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '14px',
                color: 'var(--primary)',
                border: '0px',
                padding: '4px 12px',
                backgroundColor: 'var(--campaign-finish-view-audience-label-bg)',
                borderRadius: '14px',
              }}>
                Save as new template
              </Button>
            }
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '479px',
                  p: 4,
                  borderRadius: '6px',
                  backgroundColor: 'var(--background-default)',
                }}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '16px',
                }}>
                  <IconButton style={{ position: 'absolute', top: '10px', left: '10px' }} edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseOutlined />
                  </IconButton>
                  <span style={{
                    fontWeight: 700,
                    fontSize: '36px',
                  }}>Name your new template</span>
                </div>
                <TextField style={{
                  marginBottom: '16px',
                  background: 'var(--search-input-background)',
                  borderRadius: '10px',
                }}
                  autoFocus
                  margin="dense"
                  id="name"
                  placeholder='Template name...'
                  type="text"
                  fullWidth
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  InputProps={{
                    style: {
                      color: 'var(--primary)', // This line sets the text color inside the input to red
                    },
                  }}
                />
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}>
                  <Button style={{
                    color: '#7f9887',
                    fontWeight: 400,
                    background: 'transparent',
                    borderRadius: '12px',
                  }} onClick={handleClose}>Cancel</Button>
                  <Button style={{
                    color: 'white',
                    fontWeight: 400,
                    background: 'linear-gradient(44.54deg, #42D872 14.2%, #97E89C 85.23%)',
                    borderRadius: '12px',
                    marginRight: '8px',
                  }} onClick={(e) => {
                    e.stopPropagation()
                    handleSave()
                  }}>Save template</Button>
                </div>
                {/* </Container> */}
              </Box>
            </Modal>
          </>
        }
      </>
    )
  }


  const addAttachmentButton = () => {
    return (
      <Stack direction='row'>
        <WSButton
          sx={{
            color: 'var(--primary)',
            textTransform: 'none',
            letterSpacing: 0,
            fontWeight: 600,
            fontSize: '14px',
            marginRight: '12px',
          }}
          onClick={() => {
            inputRef?.current.click();
          }}>
          <AttachFileSVG style={{ alignSelf: 'center', marginInlineEnd: '5px', }} />
          Add files
        </WSButton>
      </Stack>
    )
  }



  const handleClickSaveTemplateButton = () => {
    onChange(templateLocalState)
    setSnackbarMessage('Template saved')
  }

  const toggleEditorType = () => <Stack direction="row" alignItems="center">
    <Switch
      checked={editorType === 'poll'}
      inputProps={{ 'aria-label': 'toggle poll' }}
      onChange={(event) => {
        setEditorType(prev => {
          if (prev === 'message') {
            onChange({ ...localPollState, isPoll: true })
            return 'poll'
          } else {
            onChange({ ...templateLocalState, isPoll: false })
            return 'message'
          }
        })
      }} sx={{
        '& .MuiSwitch-switchBase': {
          color: editorType === 'poll' ? toggleSwitch : '#9b9d9e !important',
        },
        '& .MuiSwitch-track': {
          backgroundColor: editorType === 'poll' ? toggleTrack : '#a8a8a8 !important',
        },
      }} />
    <Typography variant='body1' >Schedule poll</Typography>
  </Stack>


  useEffect(() => {
    setTemplateLocalState(value)
  }, [value])

  useEffect(() => {
    console.log("getMedia 08")
    if (value?.imageId) {
      const image = blobMap?.get(value?.imageId)
      console.log("getMedia 09")
      if (image) {
        handleSelectedFiles([image])
        console.log("getMedia 10")
      }
    }

  }, [])

  useEffect(() => {
    if (editorContext === 'scheduleMessage') {

      if (currentChat?.id?._serialized?.includes('g.us')) {
        dispatch(setMentions(
          currentChat?.participants?.map(contact => {
            return ({
              id: contact.id?._serialized,
              display: contact.name ?? contact.id?._serialized?.split('@')[0],
            })
          })
        ))
      }
      if (currentChat?.id?._serialized?.includes('c.us')) {
        dispatch(setMentions([{
          id: currentChat?.id?._serialized,
          display: currentChat?.contact?.name ?? currentChat?.id?._serialized?.split('@')[0],
        }]))
      }
    }

  }, [currentChat])



  useEffect(() => {
    if (debouncedInternalMessageTemplateValue || debouncedInternalPollValue) {
      if (editorType === 'message') {
        if (isEditMode) {
          dispatch(setCurrentTemplate(debouncedInternalMessageTemplateValue));
        }
        else {
          onChange({ ...templateLocalState, isPoll: false })
        }
      }
      else {
        onChange({ poll: localPollState, isPoll: true })
      }
    }
  }, [debouncedInternalMessageTemplateValue, debouncedInternalPollValue])


  useEffect(() => {
    if (pollFromWAModal) {
      setEditorType('poll')
    }
  }, [pollFromWAModal])



  const handlePlaceholderClick = (placeholder: string) => {
    const textElement = textareaRef.current;
    if (!textElement) return;

    const currentPosition = textElement.selectionStart ?? selectionRange?.end ?? templateLocalState.message?.length ?? 0;

    const updatedMessage = [
      templateLocalState.message?.slice(0, currentPosition) ?? '',
      placeholder,
      templateLocalState.message?.slice(currentPosition) ?? ''
    ].join('');

    setTemplateLocalState({
      ...templateLocalState,
      message: updatedMessage
    });

    setTimeout(() => {
      const newPosition = currentPosition + placeholder.length;
      textElement.focus();
      textElement.setSelectionRange(newPosition, newPosition);
    }, 0);
  };



  return (
    <Stack spacing={2} sx={{ position: 'relative', width: '100%', }}>



      <ReplyToMessageChip height='83px' />


      {editorType === 'message' && !isAttachmentSound &&
        <>
          {!isPreview &&
            <Stack direction='column'>
              {isEditMode && <TemplateHeader
                templateLocalState={templateLocalState}
                setTemplateLocalState={setTemplateLocalState}
                onChange={onChange}
              />}
              < Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                {fontFormatButtons()}
                <Stack direction='row' sx={{ alignItems: 'center' }}>
                  {addAttachmentButton()}
                  {isEditMode && <>{saveNewTemplateButton()}</>}
                  {!isEditMode && editorContext === 'scheduleCampaign' && <MessageEditorTemplateDropdown />}
                </Stack>
              </Stack>
            </Stack>

          }
          <Box sx={{ width: '100%', }}
            {...rootProps}
          >
            {!isPreview &&
              <>
                {isDragActive && dropFilesOverlay()}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFile}

                  {...inputProps}

                />
              </>
            }
            {!isPreview &&
              <>
                {isDragActive && dropFilesOverlay()}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={onChangeFile}

                  {...inputProps}

                />
              </>
            }

            {loadingAttachment &&
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                direction: 'ltr',
                alignItems: 'center',
                width: '100%',
                height: '125px',
              }}>
                <CircularProgress />
              </div>
            }
            {!loadingAttachment && messageBox()}


            {!isPreview &&
              <>
                <TemplatePlaceholderBar
                  value={value}
                  templateLocalState={templateLocalState}
                  showTemplateButtons={showTemplateButtons}
                  onPlaceholderClick={handlePlaceholderClick}
                />
                <Menu open={isEmojiPicker} anchorEl={emojiButtonRef.current} onClose={() => seIsEmojiPicker(false)}>
                  <EmojiPicker
                    onEmojiClick={onEmojiClick}
                  />
                </Menu>
              </>
            }
          </Box>
          {attachmentBox()}
          {
            !isPreview &&
            <>
              {linkPreview()}
            </>
          }
          {templateLocalState?._id && editorContext === 'scheduleCampaign' &&
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              {activeBulkView !== 'campaignManagementView' && // hide for campaign summary (in manage campaigns)
                <BulkButton
                  onClick={handleClickSaveTemplateButton}
                  buttonString="Save"
                  Icon={SaveAsOutlined}
                />}
            </div>
          }
          {!templateLocalState?._id && isEditMode &&
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              <BulkButton
                onClick={handleOpen}
                buttonString="Save New Template"
                Icon={SaveAsOutlined}
              />
            </div>
          }
        </>
      }
      {editorType === 'message' && isAttachmentSound &&
        <>
          <p style={{
            color: 'var(--primary)',
            fontWeight: 700,
            fontStyle: 'italic',
            fontSize: '16px',
          }}>Audio messages cannot contain text</p>

          {attachmentBox()}
        </>
      }

      {(editorContext === 'scheduleMessage' || activeTab === 'messageAndFiles') && toggleEditorType()}

      {editorType === 'poll' &&
        <CreatePoll
          localPollState={localPollState}
          setLocalPollState={setLocalPollState} />
      }

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => { setSnackbarMessage(undefined) }}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
        message={snackbarMessage}
      />
    </Stack >
  )
}