import { MessageServiceActions } from "@common/services/messageServiceActions";
import { postMessageWithResponse, postMessageToBackgroundWithResponse } from "@common/services/post-message-to-whatsapp-functions";
import { WaMessage } from "@extension/wa-communication-layer/wa-interfaces";
import { Picture } from "@wa-communication-layer/whatsapp";

export class ContentMessageServiceActions extends MessageServiceActions {

  public static postMessageWithResponse<T>(cmd: string, data: any): Promise<T | undefined> {
    return super.postMessageWithResponse(cmd, data, "contentMessageService");
  }



  public static async getMyWaProfile(): Promise<void> {
    return postMessageWithResponse({ cmd: "getMyWaProfile" });
  }

  public static async getCurrentChat(options?: { clearInput?: boolean }): Promise<void> {
    return postMessageWithResponse({
      cmd: "getCurrentChat",
      data: options?.clearInput,
    });
  }


  public static async getWAContactImageRequest(): Promise<void> {
    return postMessageWithResponse({ cmd: "getWAContactImageRequest" });
  }

  public static async focusContact(contactId: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "focusContact",
      data: contactId,
    });
  }

  public static async setNewStatus(localNewStatus: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "setNewStatus",
      data: localNewStatus,
    });
  }

  public static async openChatByPhone(phone: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "openChatByPhone",
      data: phone.replace('+', ''),
    });
  }

  public static async getMessageLogWAMessageAck(
    latestLogsArray: any[],
    campaignId: string,
    numberOfContacts: number
  ): Promise<void> {
    return postMessageWithResponse({
      cmd: "getMessageLogWAMessageAck",
      data: {
        latestLogsArray,
        campaignId,
        numberOfContacts,
      }
    });
  }

  public static async getProfilePicThumb(id: string): Promise<Picture> {
    return postMessageWithResponse({
      cmd: "getProfilePicThumb",
      data: id,
    });
  }

  public static async rebuildContactNodes(): Promise<void> {
    // return postMessageWithResponse({ cmd: "rebuild-contact-nodes" });
    console.warn("rebuildContactNodes is not implemented");
  }

  public static async updateShouldAppearChatsByList(chatsThatShouldAppearInList: any): Promise<void> {
    return postMessageWithResponse({
      cmd: "update-should-appear-chats-by-list",
      data: chatsThatShouldAppearInList,
    });
  }

  public static async getPollData(): Promise<void> {
    return postMessageWithResponse({ cmd: "getPollData" });
  }



  public static async getMedia(): Promise<{
    cmd: string,
    mediaBlobList: any[],
    caption: string,
  }> {
    return postMessageWithResponse({ cmd: "getMedia" });
  }

  public static async clearChatInput(currentChatId?: string): Promise<void> {
    return postMessageWithResponse({
      cmd: "clearChatInput",
      data: currentChatId,
    });
  }

  public static async checkIfReplyingToMessage(): Promise<void> {
    const replyData: any = await postMessageWithResponse({ cmd: "checkIfReplyingToMessage" });
    return replyData
  }

  public static async updateChatModels(contactLabels: any[]): Promise<void> {
    return postMessageWithResponse({
      cmd: "update-chat-models",
      contactLabels,
    });
  }

  public static async injectContactLabelActionsToAllMissingChats(): Promise<void> {
    return postMessageWithResponse({
      cmd: "injectContactLabeActionsToAllMissingChats",
    });
  }

  public static async getUserInfo(): Promise<void> {
    return postMessageWithResponse({ cmd: "getUserInfo" });
  }

  public static async openTab(boardURL: string): Promise<void> {
    return postMessageWithResponse({ cmd: "openTab", data: boardURL });
  }


  public static async sendMessageViaWhatsapp(preparedWaMessage: WaMessage, userMessageId: string): Promise<void> {
    const data = { waMessage: preparedWaMessage, userMessageId }
    return postMessageWithResponse({ cmd: "sendMessageViaWhatsapp", data });
  }

  public static async getUnloggedWaMessageKeyIfExistInStore(
    chatId: string,
    userMessageId: string,
    dueDate: Date
  ): Promise<void> {
    const data = { chatId, userMessageId, dueDate }
    return postMessageWithResponse({ cmd: "getUnloggedWaMessageKeyIfExistInStore", data });
  }

  public static async getChatsAndContacts(): Promise<void> {
    return postMessageWithResponse({ cmd: "getChatsAndContacts" });
  }

  public static async getProfilePicUrl(contactId: string): Promise<void> {
    return postMessageWithResponse({ cmd: "getProfilePicUrl", data: contactId });
  }

  public static async getWaMeInfo(): Promise<any> {
    return postMessageWithResponse({ cmd: "getWaMeInfo" });
  }

  public static async fetchUrlContent(url: string): Promise<string> {
    return postMessageToBackgroundWithResponse({ cmd: "fetchUrlContent", data: url });
  }

  public static async fetchUrlContentAsBlob(url: string): Promise<string> {
    return postMessageToBackgroundWithResponse({ cmd: "fetchUrlContentAsBlob", data: url });
  }
}
