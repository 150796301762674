import Enterprise from '@common-assets/enterprise.png';
import { BTPaper } from "@common-components/atoms/bt-paper/bt-paper";
import { BillingPeriod } from "@common-components/subscription-selection/subscription-selection";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography, Tooltip } from "@mui/material";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { activeSubscriptionSelector, selectUserObject } from "@common-reducers/UserSelectors";
import { useGetPlanMapMutation, useGetItemPricesMutation, useGetEntitlementsMutation} from "@common-reducers/backend-api/backend-api";
import { useAnalyticsService } from "@common-reducers/hooks/use-analytics-service";
import useObserver from "@common-reducers/hooks/use-observer";
import { AnalyticsService } from "@common-services/analytics-service";
import { useAppSelector } from "@common-reducers/hooks/store.hook";
import { namespace } from "@common-config/constants";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanDescriptor, appConfig } from "@common/config/app.config";
import { theme } from '@common/theme';
import { ButtonType } from "../bluesnap-button/bluesnap-button";
import planBg from './background-flow.png';
import classes from './plan-selection.module.scss';
import ChargebeeButton from '@common-components/chargebee-button/chargebee-button';

const BlueTicksSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(19px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#a9e5ff' : '#a9e5ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
        background: 'var(--default-background)'
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#53BDEB' : '#53BDEB',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export interface PlanSelectionProps {

}

export interface PlanSelectionState {
    selectedPlan?: any;
    loading?: boolean;
    sizeClass: string;
    buttonGroupSize: 'small' | 'medium';
    buttonGroupOrientation: 'horizontal' | 'vertical';
}
interface PaymentPlan {
    best?: boolean;
    name: string;
    planKey: string;
    key: string;
    limitations: any[];
    benefits: any[];
    common: any[];
    upgrade?: boolean;
    enterprise?: boolean;
    tip?: string;
    price?: number;
    period?: string;
}

const freeLimitsMap: Record<string, string[]> = {
    "unlimited-messages": ["planSelection.freeLimitMessages"],
    "unlimited-tasks": ["planSelection.freeLimitTasks"],
    "offline-socket": ["planSelection.freeLimitOffline"]
};

const entitlementDescriptionMap: Record<string, string> = {
    "unlimited-messages": "planSelection.entitlementMessages",
    "unlimited-tasks": "planSelection.entitlementTasks",
    "remove-branding": "planSelection.entitlementBranding",
    "offline-socket": "planSelection.entitlementOffline"
};

const featureOrder = [
    "remove-branding",
    "offline-socket",
    "unlimited-messages",
    "unlimited-tasks",
];

export const PlanSelection = () => {
    const { t } = useTranslation(namespace);
    const analyticsService: AnalyticsService = useAnalyticsService();
    const resizeElement: React.RefObject<HTMLDivElement> = createRef();
    const user = useAppSelector(selectUserObject);
    const activeSubscription = useAppSelector(activeSubscriptionSelector);
    const isGettingPaymentUrl = useAppSelector(state => state.PaymentFormReducer.isGettingPaymentUrl);
    const [getPlanMap, { isLoading: isLoadingPlanMap, data: planMap }] = useGetPlanMapMutation();
    const [getItemPrices, { isLoading: isLoadingPrices, data: itemPricesData }] = useGetItemPricesMutation();
    const [getEntitlements, { data: entitlementsData }] = useGetEntitlementsMutation();

    const [_planDuration, setPlanDuration] = useState<BillingPeriod | undefined>();
    const [_seatCount, setSeatCount] = useState<number | undefined>();
    const [state, setState] = useState<PlanSelectionState>({
        sizeClass: '',
        buttonGroupSize: 'small',
        buttonGroupOrientation: 'horizontal',
    });

    useEffect(() => {
        if (user) {
            console.log('Fetching plan data for user:', user);
            getPlanMap().unwrap()
                .then(response => console.log('Plan map response:', response))
                .catch(error => console.error('Error fetching plan map:', error));

            getItemPrices().unwrap()
                .then(response => console.log('Item prices response:', response))
                .catch(error => console.error('Error fetching item prices:', error));

            getEntitlements().unwrap()
                .then(response => console.log('Entitlements response:', response))
                .catch(error => console.error('Error fetching entitlements:', error));
        }
    }, [getPlanMap, getItemPrices, getEntitlements, user]);

    const planDuration = useMemo(() => _planDuration ?? activeSubscription?.billingPeriod ?? 'year', [_planDuration, activeSubscription?.billingPeriod])
    const monthlyBilling = useMemo(() => planDuration === 'month', [planDuration]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanDuration(event.target.checked ? 'month' : 'year');
    };

    const extensionPricing = useMemo(() => {
        return t('planSelection.extensionPricing', { returnObjects: true });
    }, [t]);

    const taskPricing = useMemo(() => {
        return t('planSelection.taskPricing', { returnObjects: true });
    }, [t]);

    const seatOptions: number[] = useMemo(() => Object.keys(planMap ?? {})
        .map(planName => planMap[planName])
        .filter(plan => plan.intervalUnit === planDuration)
        .reduce((_seatOptions, plan) => [
            ...new Set([..._seatOptions, plan.seatCount])
        ], [])
        .filter(x => x)
        .sort((a, b) => a - b)
        , [planMap, planDuration]);

    const seatCount = useMemo(() => _seatCount ?? activeSubscription?.seatCount ?? seatOptions[0] ?? 1,
        [_seatCount, activeSubscription?.seatCount, seatOptions]);

    const entitlementsMap = useMemo(() => entitlementsData || {}, [entitlementsData]);


    const planDescription: PaymentPlan[] = useMemo(() => {
        const basePlans = appConfig.pricingOptions === 'extension' ? extensionPricing : taskPricing;

        console.log('itemPricesData:', itemPricesData);
        console.log('isLoadingPrices:', isLoadingPrices);
        console.log('planDuration:', planDuration);

        // If still loading or no data yet, return plans with 0 prices
        if (isLoadingPrices || !itemPricesData) {
            return (basePlans as PaymentPlan[]).map((plan: PaymentPlan) => ({
                ...plan,
                price: 0,
                period: planDuration
            }));
        }

        return (basePlans as PaymentPlan[]).map((plan: PaymentPlan) => {
            console.log('Processing plan:', plan);

            // Map the plan key to Chargebee plan type
            const planTypeMap = {
                'Free': 'Free',
                'Scheduler': 'Basic',
                'Manager': 'Standard',
                'Campaigner': 'Pro'
            };

            const chargebeePlanType = planTypeMap[plan.key] || plan.key;
            const chargebeePlan = ((itemPricesData as unknown) as Array<{
                id: string;
                name: string;
                item_family_id: string;
                item_id: string;
                status: string;
                tiers: Array<{
                    starting_unit: number;
                    ending_unit?: number;
                    price: number;
                }>;
            }>).find(price => {
                console.log('Checking price:', price);
                console.log('Matching against planType:', chargebeePlanType);
                console.log('Matching against periodUnit:', planDuration);

                // Extract plan type from Chargebee item ID (e.g., "Basic-USD-Monthly")
                const [planType] = price.id.split('-');
                return planType === chargebeePlanType &&
                    (planDuration === 'month' ? price.id.includes('Monthly') : price.id.includes('Yearly'));
            });

            console.log('Found chargebeePlan:', chargebeePlan);

            // Find the correct tier based on seatCount
            const tier = chargebeePlan?.tiers?.find(tier =>
                seatCount >= tier.starting_unit &&
                (!tier.ending_unit || seatCount <= tier.ending_unit)
            );

            console.log('Found tier:', tier);
            const price = tier?.price ?? 0;
            console.log('Final price:', price);

            const entitlements = entitlementsMap[plan.planKey]?.reduce((acc, ent) => {
                acc[ent.feature_id] = t(entitlementDescriptionMap[ent.feature_id]);
                return acc;
            }, {} as Record<string, string>) || {};

            const sortedBenefits = featureOrder
                .map(feature => entitlements[feature])
                .filter(Boolean);

            const limitations = Object
                .keys(freeLimitsMap)
                .filter(key => !entitlementsMap[plan.planKey]?.some(ent => ent.feature_id === key))
                .map(key => t(freeLimitsMap[key]));

            return {
                ...plan,
                benefits: sortedBenefits,
                limitations,
                price: Number(Number(price / 100 / (chargebeePlan?.id.includes('Yearly') ? 12 : 1)).toFixed(1)),
                period: chargebeePlan ? (chargebeePlan.id.includes('Monthly') ? 'month' : 'year') : planDuration,
                best: chargebeePlanType === 'Standard'
            };
        });
    }, [extensionPricing, taskPricing, itemPricesData, isLoadingPrices, planDuration, entitlementsMap, seatCount, t]);

    const activePlanMap: Record<string, Record<string, PlanDescriptor>> = useMemo(() => Object.keys(planMap ?? {})
        .map(planId => ({
            ...planMap[planId],
            id: planId
        }))
        .filter(plan => plan.seatCount === seatCount && plan.intervalUnit === planDuration)
        .reduce((activePlans, plan) => ({
            ...activePlans,
            [plan.planType]: {
                ...(activePlans[plan.planType] ?? {}),
                [plan.bluesnapType]: plan
            }
        }), {})
        , [planMap, seatCount, planDuration]);

    useObserver({
        element: resizeElement.current,
        callback: (entries: ReadonlyArray<ResizeObserverEntry>) => {
            const [entry] = entries;
            let sizeClass = '';
            let buttonGroupSize: 'small' | 'medium' = 'medium';
            let buttonGroupOrientation: 'horizontal' | 'vertical' = 'horizontal';
            if (entry.contentRect.width < 945) {
                sizeClass = classes.small;
                buttonGroupSize = 'small';
            }

            if (entry.contentRect.width < 450) {
                buttonGroupOrientation = 'vertical';
            }

            setState({
                ...state,
                sizeClass,
                buttonGroupSize,
                buttonGroupOrientation
            })
        }
    })

    const updatePlanDuration = (event: React.MouseEvent<HTMLElement, MouseEvent>, newPlanDuration: any) => {
        console.log(`Plan duration: ${planDuration}`);
        setPlanDuration(newPlanDuration);
    }

    const subscriptionBox = (index: number, paymentPlanData: PaymentPlan) => {
        const planAndContract: Record<string, PlanDescriptor> = activePlanMap[paymentPlanData.key];
        const plan = planAndContract?.plan;
        const activePlanSeatCount = activeSubscription?.seatCount ?? 1;
        const planSeatCount = plan?.seatCount ?? 1;
        const intervalMonths = plan?.intervalUnit === 'month' ? 1 : 12;
        const activeIntervalMonths = activeSubscription?.billingPeriod === 'month' ? 1 : 12;
        const activeSubscriptionOrder = activeSubscription?.order ?? 0;
        const planOrder = plan?.order ?? 0;

        let activeScore = activeSubscriptionOrder * 1000 + activePlanSeatCount * 100 + activeIntervalMonths;
        let planScore = planOrder * 1000 + planSeatCount * 100 + intervalMonths;

        let CTA: string;
        let buttonType: ButtonType;
        let upgrade = false;

        const isCurrentPlan = planScore === activeScore;
        if (planScore > activeScore) {
            CTA = t('planSelection.upgrade');
            buttonType = ButtonType.Upgrade;
            upgrade = true;
        } else if (isCurrentPlan) {
            CTA = t('planSelection.currentPlan');
            buttonType = ButtonType.Current;
        } else {
            CTA = t('planSelection.downgrade');
            buttonType = ButtonType.Downgrade;
        }

        let actionButton;
        if (!paymentPlanData.enterprise && paymentPlanData.key !== "Free") {
            if (isLoadingPrices || (!plan && index > 0)) {
                actionButton = <CircularProgress />;
            } else {
                actionButton =
                    <Box data-testid={`subscription-upgrade-button-${paymentPlanData.name}`}>
                        <ChargebeeButton
                            plan={paymentPlanData.planKey}
                            duration={paymentPlanData.period === 'month' ? "Monthly" : "Yearly"}
                            label={CTA}
                            disabled={isCurrentPlan}
                            seats={seatCount}
                        />
                    </Box>
            }
        } else {
            actionButton = <Box />;
        }

        return <BTPaper
            key={index}
            className={[classes.planContainer, paymentPlanData.best ? classes.selected : ''].join(' ')}
            style={{ backgroundImage: paymentPlanData.best ? `url(${planBg})` : '', backgroundSize: 'cover' }}
        >
            <div className={classes.planName} style={{ marginBottom: theme.spacing(1) }}> {paymentPlanData.name} </div>
            {!paymentPlanData.enterprise && <div>
                <div>
                    <span className={classes.currency}>{paymentPlanData.price ? '$' : (paymentPlanData.key === 'Free' ? '$' : '')}</span>
                    <span className={classes.price}>{paymentPlanData.key === 'Free' ? '0' : paymentPlanData.price}</span>
                </div>
                {!!paymentPlanData.price && <div style={{ fontSize: '14px', marginTop: '4px', textAlign: 'center' }}>/ user / month</div>}
            </div>}
            {paymentPlanData.enterprise && <Box>
                <img src={Enterprise} alt={t('planSelection.enterprise')} style={{ height: "83px" }} />
            </Box>}
            {paymentPlanData.key !== "Free" &&
                <div style={{ opacity: paymentPlanData.price ? 1 : 0, marginBottom: theme.spacing(2), color: paymentPlanData.best ? "var(--default-background)" : 'inherit' }} >
                    {planDuration === 'month' ? t('planSelection.perMonth') : t('planSelection.billedAnnually')}
                </div>
            }

            {paymentPlanData.key === "Free" && <Box>
                <div style={{ marginBottom: theme.spacing(2) }} >
                    {t('pricing.freeForever')}
                </div>
                <br />
            </Box>}

            <div style={{
                display: 'flex',
                gap: '18px',
                flexDirection: 'column',
                paddingLeft: "25px",
                margin: "12px 0",
                minHeight: "310px",
            }}>
                {[
                    ...paymentPlanData.benefits.map((benefit, index) => {
                        const isOfflineFeature = benefit.includes(t(entitlementDescriptionMap["offline-socket"]));
                        return (
                            <span className={`${classes.attributesContainer} ${paymentPlanData.best ? classes.upgradePlan : ''}`} key={`${paymentPlanData.name}-benefit-${index}`}>
                                <Tooltip 
                                    title={isOfflineFeature ? "Send your scheduled messages 24/7 - even when your computer is off!" : ""}
                                    arrow
                                    placement="top"
                                >
                                    <span>
                                        <b>{benefit}</b>
                                    </span>
                                </Tooltip>
                            </span>
                        );
                    }),
                    ...paymentPlanData.limitations.map((limitation, index) =>
                        <span className={`${classes.attributesContainer} ${paymentPlanData.best ? classes.upgradePlan : ''}`} key={`${paymentPlanData.name}-limitation-${index}`}>
                            <span>{limitation}</span>
                        </span>
                    )
                ]}
            </div>
            {(user ?
                <Box>
                    {actionButton}
                    {paymentPlanData.enterprise &&
                        <Button href="https://tudoboard.com/contact" className={classes.enterprise}>
                            {t('planSelection.contactSales')}
                        </Button>
                    }
                </Box> :
                <Button
                    className={classes.selectPlanButton}
                    href="/login?redirect=/account-subscription"
                    style={{
                        backgroundColor: '#2C2E2F',
                        color: '#ffffff'
                    }}>{t('planSelection.loginToBuy')}
                </Button>)
            }

            {(paymentPlanData.best) &&
                <span className={classes.mostPopular}>{t('planSelection.mostPopular')}</span>}
        </BTPaper>;
    }

    console.log('isGettingPaymentUrl', isGettingPaymentUrl);

    return <>
        {(isLoadingPrices || isLoadingPlanMap || isGettingPaymentUrl) ?
            <div style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                width: "100%",
                height: "80vh",
                flexDirection: "column",
                gap: "20px"
            }}>
                <CircularProgress />
            </div>
            :
            <div ref={resizeElement} className={state.sizeClass}>
                {/* <PaymentForm /> */}

                <Stack className={classes.actionsContainer} direction="row">
                    <FormControl sx={{ width: 200 }}>
                        <div style={{ color: 'var(--secondary-text-color)', fontSize: '14px', marginBottom: '10px' }}>{t('planSelection.teamSize')}</div>
                        <Select
                            data-testid='buy-subscription-view-team-size-select'
                            sx={{ width: '125px', height: '36px', background: 'var(--background-default)', borderRadius: '8px', border: 'none!important' }}
                            labelId="seat-count-select-label"
                            id="seat-count-select"
                            value={seatCount}
                            IconComponent={ExpandMoreIcon}
                            onChange={(event) => {
                                analyticsService.event(`set_team_size`, {
                                    category: "buySubscriptionView",
                                    action: `set team size ${event.target.value.toString()}`,
                                });
                                setSeatCount(parseInt(event.target.value.toString()))
                            }}
                        >
                            {(seatOptions ?? []).map((option, index) => <MenuItem data-testid={`buy-subscription-view-team-size-select-option-${option}`} key={index} value={option}>{option}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',

                    }}>
                        <div style={{ color: 'var( --secondary-text-color)', fontSize: '14px', marginBottom: '10px' }}>{t('planSelection.billingPeriod')}</div>
                        <Stack sx={{ height: '36px', background: 'var(--background-default)', borderRadius: '8px', padding: '0 20px;' }} direction="row" spacing={1} alignItems="center">
                            <Typography sx={{ color: 'var(--billing-blue-title)', fontSize: '14px', fontWeight: 700 }}>{t('planSelection.annually')}</Typography>
                            <BlueTicksSwitch
                                data-testid={`buy-subscription-view-team-size-select-option-${planDuration}`}
                                sx={{ margin: '0 10px' }}
                                checked={monthlyBilling}
                                color="primary"
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                onClick={(e) => {
                                    analyticsService.event(`set_billing_period_${monthlyBilling ? 'anually' : 'monthly'}`, {
                                        category: "buySubscriptionView",
                                        action: `set billing period ${monthlyBilling ? 'anually' : 'monthly'}`,
                                    });
                                    updatePlanDuration(e, monthlyBilling ? 'year' : 'month')
                                }} />
                            <Typography sx={{ color: 'var(--billing-blue-title)', fontSize: '14px' }}>{t('planSelection.monthly')}</Typography>
                        </Stack>
                    </Box>
                </Stack>
                {activePlanMap ? <div className={classes.planListContainer}>
                    {
                        planDescription?.map((plan, index) => subscriptionBox(index, plan))
                    }
                </div> : <Box>
                    <CircularProgress />
                </Box>}
            </div>
        }
    </>
}

export default PlanSelection;